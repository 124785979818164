<template>
  <div class="ContactPerson">
    <div class="searchNav">
      <!-- <h2>联系人</h2> -->
      <el-button
        class="button"
        icon="el-icon-plus"
        type="primary"
        style="margin-left: 0"
        @click="handleAdd"
        >新增</el-button
      >
      <el-button class="button1" type="text" @click="handleDownload" style="color: #d50700;"
        >下载模板</el-button
      >
      <el-button class="button2" type="text" @click="event" style="color: #d50700;">导入数据</el-button>
      <el-button class="button3" type="text" @click="openDialog" style="color: #d50700;"
        >添加扩展项</el-button
      >
      <el-button class="button4" type="text" @click="openDialogGoodsTypeAdd" style="color: #d50700;"
        >商品分类</el-button
      >

      <div class="searchParams">
        <div>
          <radioGroup @radioChange="radioChange" @complete="radioComplete" />
        </div>
        <el-input
          class="input"
          v-model="queryParam.keyword"
          placeholder="请输入货品名称/编码"
          clearable
          @keydown.enter.native="getDataList"
        ></el-input>
        <el-button class="button" @click="getDataList">查询</el-button>
      </div>
    </div>
    <div>
      <el-table
        :data="tableData"
        style="width: 100%"
        :max-height="height"
        v-loading="loading"
      >
        <!-- <el-table-column prop="CompanyName" label="所属公司" width="200">
        </el-table-column> -->
        <el-table-column prop="cInvCode" label="编号"> </el-table-column>
        <el-table-column prop="cInvName" label="品名"> </el-table-column>
        <el-table-column prop="cInvStd" label="包装"> </el-table-column>
        <el-table-column prop="Weight" label="重量(KG)" align="right">
          <template slot-scope="scope">{{
            toThousands(scope.row.Weight)
          }}</template>
        </el-table-column>
        <el-table-column prop="iInvRCost" label="销售价格" align="right">
          <template slot-scope="scope">{{
            toThousands(scope.row.iInvRCost)
          }}</template>
        </el-table-column>
        <el-table-column label="操作" width="70" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="handleView(scope.row)"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        layout="total,prev, pager, next,sizes"
        :total="pagination.total"
        :current-page="pagination.current"
        :page-size="pagination.pageSize"
        :page-sizes="pagination.pageSizeOptions"
        style="text-align: right; margin-top: 8px; padding: 0"
        @current-change="currentChange"
        @size-change="handleSizeChange"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="导入数据"
      :visible.sync="dialogVisible"
      width="20%"
      destroy-on-close
    >
      <el-upload
        style="text-align: center"
        class="upload-demo"
        :action="`${$baseUrl}/BO/Inventory/Import?CompanyId=${this.queryParam.SupplieId} `"
        :on-success="handlesuccess"
        :on-progress="handleprogres"
        :limit="10"
        :on-exceed="handleExceed"
        :file-list="fileList"
      >
        <el-button size="small" type="primary">点击上传</el-button>
      </el-upload>
    </el-dialog>
    <EditForm ref="editForm" :parentObj="{ ...this }" />
    <GoodsAddSymbol ref="GoodsAddSymbol" />
    <GoodsTypeAdd ref="GoodsTypeAdd" :parentObj="{ ...this }"></GoodsTypeAdd>
  </div>
</template>

<script>
import radioGroup from "@/components/CompanyRadioGroup.vue";
import { toThousands, download } from "@/utils/Tools";
import EditForm from "./GoodsInfoForm.vue";
// 引用商品分类组件
import GoodsTypeAdd from "./GoodsTypeAdd.vue";
import GoodsAddSymbol from "@/components/GoodsAddSymbol.vue";
export default {
  props: {},
  components: {
    EditForm,
    GoodsTypeAdd,
    radioGroup,
    GoodsAddSymbol,
  },
  mounted() {},
  created() {},
  computed: {
    height() {
      return document.documentElement.clientHeight * 0.75;
    },
  },
  data() {
    return {
      dialogVisible: false,
      pagination: {
        current: 1,
        pageSize: 25,
        total: 0,
        pageSizeOptions: [25, 50, 100],
      },
      sorter: {
        field: "Id",
        order: "desc",
      },
      tableData: [],
      fileList: [],
      queryParam: { condition: "cInvName", SupplieId: "全部" },
      loading: false,
      toThousands,
    };
  },
  methods: {
    // 增补项
    openDialog() {
      if (this.queryParam.SupplieId.split(",").length > 1) {
        this.$message.error("请选择公司");
      } else {
        this.$refs.GoodsAddSymbol.openDialog(this.queryParam.SupplieId);
      }
    },
    // 商品分类
    openDialogGoodsTypeAdd() {
      if (this.queryParam.SupplieId.split(",").length > 1) {
        this.$message.error("请选择公司");
      } else {
        this.$refs.GoodsTypeAdd.openForm(this.queryParam.SupplieId);
      }
    },
    handleprogres(event, file, fileList) {},
    handlesuccess(response, file, fileList) {
      if (response.Success) {
        this.$message.success(response.Msg);
        this.dialogVisible = false;
        setTimeout(() => {
          this.getDataList();
        });
      } else {
        this.$message.error(response.Msg);
        this.fileList = [];
      }
    },

    handleExceed() {
      this.$message.error("已超出文件列表最大数，请删除后上传");
      console.log("文件超出限制");
    },
    event() {
      if (this.queryParam.SupplieId.split(",").length > 1) {
        this.$message.error("请选择公司");
        return;
      }
      console.log("公司Id:" + this.queryParam.SupplieId);
      console.log("公司名称:" + this.queryParam.SupplieId);
      this.dialogVisible = true;
      this.dialogVisible = true;
    },
    handleDownload() {
      if (this.queryParam.SupplieId.split(",").length > 1) {
        this.$message.error("请选择公司");
        return;
      }
      download(
        this.$baseUrl +
          "/BO/Inventory/ExportToExcel?CompanyId=" +
          this.queryParam.SupplieId +
          "",
        "货品导入模板"
      );
    },
    radioComplete(e) {
      if (e) {
        this.queryParam.SupplieId = e;
        this.getDataList();
      }
    },
    radioChange(e) {
      if (e == "more") return;
      this.queryParam.SupplieId = e;
      this.getDataList();
    },
    handleAdd() {
      if (this.queryParam.SupplieId.split(",").length > 1) {
        this.$message.error("请选择公司");
      } else {
        this.$refs.editForm.openForm(null, this.queryParam.SupplieId);
      }
    },
    handleView(row) {
      this.$refs.editForm.openForm(row.Id);
    },
    currentChange(page) {
      this.pagination.current = page;
      this.getDataList();
    },
    handleSizeChange(lint) {
      this.pagination.pageSize = lint;
      this.getDataList();
    },
    getDataList() {
      this.loading = true;
      this.$http
        .post("/BO/Inventory/GetDataList", {
          PageIndex: this.pagination.current,
          PageRows: this.pagination.pageSize,
          SortField: this.sorter.field || "Id",
          SortType: this.sorter.order,
          Search: this.queryParam,
        })
        .then((resJson) => {
          this.loading = false;
          if (resJson.Success) {
            this.tableData = resJson.Data;
            if (this.tableData) {
              const pagination = { ...this.pagination };
              pagination.total = resJson.Total;
              this.pagination = pagination;
            }
          }
        });
    },
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.searchNav .button {
  background: #d50700;
  border-color: #d50700;
  color: #fff;
}
/deep/.el-radio-button__orig-radio:checked+.el-radio-button__inner {
    color: #fff;
    background-color: #d50700;
    border-color: #d50700;
    box-shadow: -1px 0 0 0 #d50700;
}
/deep/.el-radio-button__inner:hover {
    color: #d50700;
}</style>
